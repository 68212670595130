import _get from "lodash.get";
import moment from "moment";

export const referralStatuses = [
  { value: "", label: "Избери статус" },
  { value: "Pending", label: "Обработва се" },
  { value: "Completed", label: "Завършен" },
  { value: "Cancelled", label: "Отказан" }
];
export const referralTypes = [
  { value: "User", label: "Потребителски" },
  { value: "System", label: "Системен" }
];

export const fields = [
  {
    name: "referralEmail",
    type: "text",
    label: "Имейл на referral",
    placeholder: "referral@boleron.com",
    defaultValueFunction: props => {
      return _get(props, "referralEmail", "");
    },
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "referredEmail",
    type: "text",
    label: "Имейл на referred",
    placeholder: "referred@boleron.com",
    defaultValueFunction: props => {
      return _get(props, "referredEmail", "");
    },
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "type",
    type: "select",
    label: "Тип *",
    value: "User",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: referralTypes
  },
  {
    name: "status",
    type: "select",
    label: "Статус *",
    value: "Pending",
    readOnly: true,
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: referralStatuses
  },
  {
    name: "config",
    type: "select",
    label: "Конфигурация *",
    value: "None",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: props => {
      console.log(props);
      return _get(props, "referralConfigList", []);
    }
  },
  {
    name: "expiration",
    type: "date",
    label: "Валидност до *",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    value: moment().add(30, "days")
  }
];
