export const countQueriesKeys = {
  GO: [{ key: "queries", status: "pending" }],
  CSC: [
    { key: "queries.query", status: "pending", offerStatus: "offerstatusquery", offer: "offline" },
    { key: "queries.confirmed", status: "pending", offerStatus: "offerstatusconfirmed" }
  ]
};

// todo ако излезнат случаи, в които има повече от един count за основен таб installments, тогава този конфиг ще се ползва по подобие на countQueriesKeys
export const countInstallmentsKeys = {
  GO: [{ key: "installments.confirmed", variant: "confirmed" }],
  CSC: [{ key: "installments.confirmed", variant: "confirmed" }],
  PRP: [{ key: "installments.confirmed", variant: "confirmed" }]
};

// todo CSC+GO keys
// ["CSC+GO"]: [
//   { key: "queries", statusCombined: "pending" },
//   { key: "offers.query", statusCombined: "pending-offer" },
//   { key: "offers.confirmed", statusCombined: ["pending", "just-pending", "other"], offerStatusCombined: "offerstatusconfirmed" }
// ]

export const calculateTotalCount = (data, product, keyPrefix) => {
  if (!data?.[product]) return 0;

  return Object.entries(data[product])
    .filter(([key]) => key.startsWith(keyPrefix))
    .reduce((sum, [, value]) => sum + value, 0);
};

export const calculateTotalByProduct = (data, product) => {
  if (!data?.[product]) return 0;

  return Object.values(data[product]).reduce((sum, value) => sum + value, 0);
};
