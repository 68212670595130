import React, { createElement, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import qs from "qs";
import _get from "lodash.get";
import routesConfig from "../../../routes/routesConfig";
import { capitalizeFirstLetter, getKeyByValue } from "../../../utils/helpers";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import _find from "lodash.find";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import ActionsProvider from "../../ActionsProvider";

const TRA = props => {
  const insurerIdLookup = {
    allianz: "Алианц",
    bulstrad: "Булстрад",
    dzi: "ДЗИ",
    euroins: "Евроинс",
    instinct: "Инстинкт",
    levins: "Лев Инс",
    uniqa: "Уника"
  };

  const policiesConfig = {
    active: "Активни",
    cancelled: "Прекратени",
    all: "Всички"
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });
  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "queries");
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));
  const [policy, setPolicy] = useState({});

  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");

  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };
  const handleAction = async (policy, option, refreshDataMethod) => {
    setPolicy(policy);
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.name}`);
    }
  };

  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Пътуване в чужбина" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            objWithEgnAndRequestId: !["refundModal"].includes(k) ? { ...policy, policyDBId: policy["_id"], paymentId: "1" } : undefined,
            variant: selectedTab,
            subVariant: undefined
          });
        })}

        {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
          <PoliciesTableNew
            title=""
            variant={"policies"}
            subVariant={"active"}
            productLookup={{
              TRA: "Пътуване"
            }}
            primacy="policyPrimacy"
            productDefaultFilter={["TRA"]}
            statusLookup={{
              active: "активна"
            }}
            statusDefaultFilter={["active", "renewal", "expiring"]}
            offerDefaultFilter={[]}
            insurerIdLookup={insurerIdLookup}
            insurerIdDefaultFilter={[]}
            handleAction={handleAction}
            searchQuery={searchQuery}
            fromDays="-15"
            toDays="30"
            listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "depositedTotal"]}
            setSelectedTab={setSelectedTab}
            innerTabs={innerTabsPolicies}
          />
        )}
        {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
          <PoliciesTableNew
            title=""
            variant={"policies"}
            subVariant={"cancelled"}
            productLookup={{
              TRA: "Травъл"
            }}
            primacy="policyPrimacy"
            productDefaultFilter={["TRA"]}
            statusLookup={{
              cancelled: "прекратена"
            }}
            statusDefaultFilter={["cancelled"]}
            offerDefaultFilter={[]}
            insurerIdLookup={insurerIdLookup}
            insurerIdDefaultFilter={[]}
            handleAction={handleAction}
            searchQuery={searchQuery}
            fromDays="-15"
            toDays="30"
            listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "depositedTotal"]}
            setSelectedTab={setSelectedTab}
            innerTabs={innerTabsPolicies}
          />
        )}
        {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
          <PoliciesTableNew
            title=""
            variant={"policies"}
            subVariant={"all"}
            productLookup={{
              TRA: "Травъл"
            }}
            primacy="policyPrimacy"
            productDefaultFilter={["TRA"]}
            statusLookup={{
              active: "активна",
              expired: "изтекла",
              cancelled: "прекратена"
            }}
            statusDefaultFilter={[]}
            offerDefaultFilter={[]}
            insurerIdLookup={insurerIdLookup}
            insurerIdDefaultFilter={[]}
            handleAction={handleAction}
            searchQuery={searchQuery}
            fromDays="-15"
            toDays="30"
            listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "depositedTotal"]}
            setSelectedTab={setSelectedTab}
            innerTabs={innerTabsPolicies}
          />
        )}
      </ActionsProvider>
    </PageLayout>
  );
};
export default withMultipleModals(withParams(TRA), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createPolicyModal" },
  { id: "riskyUserProfileModal" },
  { id: "downloadPolicyPdfModal" }
]);
