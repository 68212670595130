import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import moment from "moment";
import { success, warning, gray } from "../../utils/colors";
import { getPaymentByRequestIdAndPaymentId } from "../../services/PoliciesService";

const ProductAndInsurerInfo = props => {
  const [payment, setPayment] = useState(null);
  const policy = _get(props, "policy");
  const productName = _get(props, "policy.policyData.productName");
  const isCarValid = _get(props, "policy.isCarValid", true);
  const incompleteKATData = _get(props, "policy.policyData.savedCar.incompleteKATData", false);
  const insurerName = _get(props, "policy.policyData.insurerName");
  const productId = _get(props, "policy.productId");
  const installments = _get(props, "policy.policyData.installments");
  const paymentMode = _get(policy, "policyData.paymentMode");
  const isOffline = _get(props, "policy.isOffline");
  const activeGO = _get(props, "policy.policyData.activeGo");
  const carTalonNumber = _get(props, "policy.policyData.savedCar.talonNo");
  const validFrom = _get(props, "policy.validFrom");
  const validTo = _get(props, "policy.validTo");
  const isAForeignInstallment = Boolean(_get(props, "policy.policyData.goPolicyNumber"));
  const paidTo = _get(props, "policy.policyData.paidTo");
  const cscService = _get(props, "policy.policyData.cscService");
  const cscPlusGoWarning = policy?.subProduct === "CSC+GO" && !policy?.groupRequestId;
  const variant = props?.variant;
  const subVariant = props?.subVariant;

  useEffect(() => {
    setPayment(null);
  }, [policy]);

  const showPaymentType = async () => {
    try {
      if (props?.type === "installments") {
        if (!isOffline && policy?.duePayment?.paymentId) {
          const response = await getPaymentByRequestIdAndPaymentId(policy?.requestId, policy.duePayment.paymentId);
          setPayment(_get(response, "data"));
        }
      } else {
        if (paymentMode === 1 && !payment) {
          const response = await getPaymentByRequestIdAndPaymentId(policy?.requestId, "1");
          setPayment(_get(response, "data"));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const cscServiceName = (s => {
    if (s === "EE") return "Експертна оценка";
    if (s === "TS") return "Доверен сервиз";
    if (s === "OS") return "Официален сервиз";
  })(cscService);

  const cscServiceColor = (s => {
    if (s === "EE") return gray;
    if (s === "TS") return warning;
    if (s === "OS") return success;
  })(cscService);

  const cscCarPrice = _get(props, "policy.policyData.cscCarPrice");

  const yearsTerm = _get(props, "policy.policyData.policyYears");
  const term = (years => {
    if (years === 1) return `${years} година`;
    if (years > 1) return `${years} години`;
    return null;
  })(yearsTerm);

  const ShowTransactionTypeInfo = () => (
    <>
      <span onClick={showPaymentType} className="has-text-primary cursor-pointer">
        &nbsp; покажи →
      </span>
      <strong>
        &nbsp;
        {(payment => {
          if (["OC", "RP"].includes(payment?.transactionType)) return `FIBank (${payment?.transactionType})`;
          if (payment?.transactionType === "MYPOS") return "MyPos";
          if (payment === "no payment") return "няма плащане";
          return "";
        })(payment)}
      </strong>
    </>
  );

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div>
          <b>Продукт:</b> <span>{productName}</span>
        </div>
        <div>
          <b>Застраховател:</b> <span>{insurerName}</span>
        </div>
        {["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(productId) && (
          <div>
            <b>Срок:</b> <span>{term || " - "}</span>
          </div>
        )}
        <div>
          <b>От: </b>
          <span>{moment(validFrom).format("DD.MM.YYYY")}</span>
        </div>
        <div>
          <b>До: </b>
          <span>{moment(validTo).format("DD.MM.YYYY")}</span>
        </div>
        {["CSC", "GO"].includes(productId) && !isAForeignInstallment && (
          <>
            <div className={`${isCarValid ? (incompleteKATData ? "orange" : "") : "red"}`}>
              <b>Автомобил: </b>
              {(productId === "GO" || productId === "CSC") && (
                <span>
                  {_get(policy, productId === "GO" ? "policyData.goRegNumber" : "regNumber")} - {_get(policy, "policyData.savedCar.makeModel")}{" "}
                  {_get(policy, "policyData.isNotRegisteredVehicle", false) && (
                    <>
                      {_get(policy, "policyData.make")} {_get(policy, "policyData.model")} {_get(policy, "policyData.vinNumber")}{" "}
                      <span className="red is-size-6">нерегистриран</span>
                    </>
                  )}
                  {`${isCarValid ? "" : " - невалиден"} ${incompleteKATData ? " - непълни данни" : ""}`}
                </span>
              )}
            </div>

            <div>
              <b>Номер на талон: </b> <span className="">{carTalonNumber || "-"} </span>
            </div>
            {productId === "GO" && (
              <div>
                <b>Шофьорски стаж:</b> <span className="">{_get(policy, `policyData.goExperience`)} </span>
              </div>
            )}
          </>
        )}
        <div>
          <b>Вноски:</b> <span>{installments}</span>
        </div>
        <div className="red">
          <b>Начин на плащане: </b>
          {props?.type === "installments" ? (
            <span>
              {isOffline ? "Наложен платеж" : "Банкова карта"}
              {!isOffline && !(variant === "installments" && ["process", "paidElsewhere"].includes(subVariant)) && <ShowTransactionTypeInfo />}
            </span>
          ) : (
            <span>
              {paymentMode === 1 && "Банкова карта"}
              {paymentMode === 2 && "Наложен платеж"}
              {paymentMode === 3 && "Банков превод"}
              {paymentMode === 1 && !(variant === "offers" && ["offers", "query"].includes(subVariant)) && !(variant === "queries" && ["query", "offers"].includes(subVariant)) && (
                <ShowTransactionTypeInfo />
              )}
            </span>
          )}
        </div>
        {productId === "OFF" && paidTo && (
          <div>
            <b>Заплаща се на:</b>
            <span>{paidTo === "insurer" || paidTo === "broker" ? (paidTo === "insurer" ? "Застрахователя" : "Брокера") : paidTo}</span>
          </div>
        )}
        {productId === "GO" && activeGO && (
          <div className="red">
            <b>Статус ГФ:</b> <span>{activeGO}</span>
          </div>
        )}
        {productId === "CSC" && (
          <>
            <div>
              <b>Вид сервиз:&nbsp;</b>
              <b style={{ color: cscServiceColor }}>{cscServiceName}</b>
            </div>
            <div>
              <b>Застрахователна сума:&nbsp;</b>
              <span>{cscCarPrice}лв.</span>
            </div>
          </>
        )}
      </div>
      <div className="detail-panel-gray-color-section" style={{ marginBottom: 20 }}>
        <div>
          <b>Policy ID:</b> <span>{policy?.policyId}</span>
        </div>
        <div>
          <b>DB Policy ID:</b> <span>{policy?._id}</span>
        </div>
        <div>
          <b>Request ID:</b> <span>{policy?.requestId}</span>
        </div>
        <div>
          <b className={cscPlusGoWarning ? "has-text-danger" : ""}>Group Request ID:</b> <span>{policy?.groupRequestId}</span>
          {cscPlusGoWarning && <span className="has-text-danger">липсва</span>}
        </div>
      </div>
    </>
  );
};

export default ProductAndInsurerInfo;
