import { configureStore } from "@reduxjs/toolkit";
import auth, { verifyAuth } from "./reducers/auth";
import form from "./reducers/form";
import countReducer from "./reducers/count";
import { load, save } from "redux-localstorage-simple";

const preloadedState = load({ namespace: "backOfficeApp" });
delete preloadedState?.auth;
const store = configureStore({
  reducer: {
    auth,
    form,
    count: countReducer
  },
  preloadedState: preloadedState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(save({ namespace: "backOfficeApp" }))
});
store.dispatch(verifyAuth());

export default store;
