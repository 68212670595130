import React, { useState } from "react";
import withValidation from "../../HOC/withValidation";
import { fields } from "../../../config/AddReferral.page.config";
import { Box, Button, Icon, LinearProgress } from "@mui/material";
import { gray, success } from "../../../utils/colors";
import { addReferral } from "../../../services/PoliciesService";

import _get from "lodash.get";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";

const referral = {
  referralDiscount: "15%",
  referredDiscount: "10%"
};

const AddReferralForm = props => {
  const { renderFields, callback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    if (props?.handleValidation()) {
      try {
        const {
          formData: { referralEmail, referredEmail, status, expiration, type, config }
        } = props || {};

        setIsLoading(true);
        const response = await addReferral({
          referralEmail,
          referredEmail,
          expiration,
          status,
          type,
          config,
          referredDiscount: referral?.referredDiscount
        });
        if (_get(response, "status") === 200) {
          setIsLoading(false);
          callback(_get(response, "data"));
          successAlert("sent successfully!");
        }
      } catch (e) {
        errorAlert(e?.response?.data || e?.message);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <Box m={2}>{isLoading && <LinearProgress />}</Box>
      <Box mb={2} style={{ display: "flex", flexDirection: "column" }}>
        {renderFields?.(props, fields)}
        <br />
        <Button
          onClick={handleSubmit}
          disabled={isLoading}
          type="submit"
          variant="contained"
          size="small"
          style={{
            background: !isLoading ? success : gray,
            color: "#fff",
            width: "min-content",
            alignSelf: "flex-end"
          }}
          endIcon={<Icon>arrow_right</Icon>}
        >
          Запиши
        </Button>
      </Box>
    </>
  );
};

export default withValidation(AddReferralForm, { txtFields: fields });
