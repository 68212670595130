import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { getReferralConfigList, getReferrals, removeReferral } from "../../../services/PoliciesService";
import { Grid, Icon, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import _get from "lodash.get";
import { danger } from "../../../utils/colors";

import moment from "moment/moment";
import AddReferralForm from "./AddReferralForm";

class AddReferal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      referralConfigList: []
    };
  }
  async componentDidMount() {
    const response = await getReferrals();
    const configResponse = await getReferralConfigList();
    this.setState({
      list: _get(response, "data", []),
      referralConfigList: _get(configResponse, "data", [])
    });
  }

  handleRemove = async id => {
    const response = await removeReferral({
      id
    });
    const { list } = this.state;
    const removeIndex = list.map(item => item["_id"]).indexOf(_get(response, "data._id"));
    const newList = [...list];
    if (removeIndex >= 0) {
      newList.splice(removeIndex, 1);
      this.setState({ list: newList });
    }
  };

  getStatusColor = status => {
    switch (status) {
      case "None":
        return "gray-light";
      case "Pending":
        return "warning";
      case "Cancelled":
        return "danger";
      case "Completed":
        return "success";
      case "Used":
      case "UsedOnce":
      case "UsedMoreThanOnce":
        return "gray";
      default:
        return "gray";
    }
  };

  callback = data => {
    this.setState(prevState => {
      return { list: [data, ...prevState.list], code: "", percentage: 0 };
    });
  };

  render() {
    const { list, referralConfigList } = this.state;
    return (
      <PageLayout title="Добавяне на реферал" {...this.props}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AddReferralForm callback={this.callback} referralConfigList={referralConfigList} />
          </Grid>
          <Grid item xs={9}>
            <List dense>
              {list.map(item => (
                <ListItem key={item["_id"]}>
                  <ListItemIcon>
                    <Icon style={{ color: danger }}>connect_without_contact_icon</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.referralEmail} -> ${item.referredEmail} (exp: ${item.expiration ? moment(item.expiration).format("DD-MM-YYYY") : " - "}) статус: ${
                      item.status
                    }`}
                    secondary={
                      <>
                        <Icon className={`status-icon ${this.getStatusColor(item.status)}`}>circle</Icon>{" "}
                        <span className={`status-text ${this.getStatusColor(item.status)}`}>{item.status}</span>
                      </>
                    }
                  />
                  <ListItemIcon>
                    <Icon style={{ color: danger, cursor: "pointer" }} onClick={() => this.handleRemove(_get(item, "_id", 0))}>
                      clear
                    </Icon>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

export default AddReferal;
