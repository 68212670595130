import React from "react";
import _get from "lodash.get";
import { resendFilesToEvrotrust } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { success } from "../../utils/colors";

class ResendToEvrotrustConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
  }
  handleTxtChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  };

  render() {
    const { modalName, isResendToEvrotrustModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const modalTitle = "Повторно изпращане към Евротръст";
    const btnTxt = "Изпрати";

    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={success}
        btnTxt={btnTxt}
        isModalOpen={isResendToEvrotrustModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{ ...objWithEgnAndRequestId }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      />
    );
  }
}
const generatePayloadRequest = props => {
  return {
    policyOid: _get(props, "objWithEgnAndRequestId._id")
  };
};

export default withConfirmationModal(ResendToEvrotrustConfirmationModal, {
  generatePayloadRequest,
  apiRequest: resendFilesToEvrotrust,
  modalName: "resendToEvrotrustModal",
  successMessage: "Изпратихте файловете успешно!"
});
