import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Grid, Typography, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, FormGroup, Switch, Collapse, Radio, Box } from "@mui/material";
import _get from "lodash.get";
import { getIndividualPolicy } from "../../../services/PoliciesService";
import { connect } from "react-redux";
import withParams from "../../HOC/withParams";
import FormSpeedy from "./FormSpeedy.js";
import FormEcont from "./FormEcont.js";
import { error as errorAlert } from "../../../utils/notifications";
import "./shipment.sass";

class Shipment extends React.Component {
  constructor(props) {
    super(props);
    let payment;
    if (_get(props, "location.state")) {
      payment = JSON.parse(_get(props, "location.state"));
    } else if (props?.isFromWizard && props?.variant === "installments") {
      payment = props?.policy;
    } else {
      payment = null;
    }

    this.state = {
      loading: true,
      id: _get(props, "params.id", _get(props, "policyDBId")),
      policy: {},
      payment: payment,
      initialValues: {},
      hasDelivery: !(props?.policy?.productId === "CSC" && ["bulstrad", "levins", "euroins"].includes(props?.policy?.insurerId)) // withot delivery by default for CSC levins, bulstrad, euroins
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeCourier = this.onChangeCourier.bind(this);
  }

  async componentDidMount() {
    const { id, payment } = this.state;
    const response = await getIndividualPolicy(id);
    const policy = _get(response, "data");
    const annex = this.props.annex;

    const duePayments = policy.duepayments.sort((a, b) => a.paymentId - b.paymentId);
    let duePayment = payment ?? duePayments[0];
    let duePaymentId = duePayment?._id;
    if (this.props?.isFromWizard && this.props?.variant === "installments") {
      duePaymentId = duePayment?.duePayment?._id;
    }
    if (duePayment === undefined || policy === undefined) {
      errorAlert("Policy data is not correct!");
      return true;
    }

    const deliveryCompany = _get(policy, "policyData.deliveryCompany");
    const deliveryType = _get(policy, "policyData.deliveryData.deliveryType");
    let delivery = "";
    if (deliveryType && (deliveryType === 1 || deliveryType === 2)) {
      delivery = "Speedy";
    } else if (deliveryType && (deliveryType === 3 || deliveryType === 4)) {
      delivery = "Еконт";
    } else {
      delivery = deliveryCompany;
    }
    let pickupOffice = false;
    if (policy.policyData.deliveryWay !== undefined) {
      pickupOffice = policy.policyData.deliveryWay === "До офис на куриерската фирма";
    } else {
      pickupOffice = [1, 3].includes(policy?.policyData?.deliveryData?.deliveryType);
    }

    let initialValues = {
      policyId: policy._id,
      groupRequestId: policy?.groupRequestId,
      productId: policy?.productId,
      duePaymentId,
      dueAmount: duePayment.isOffline ? duePayment.amount : 0,
      paymentId: duePayment.paymentId,
      requestId: policy.requestId,
      site: policy.policyData.deliveryCity,
      // "До адрес на клиента" "До офис на куриерската фирма"
      pickupOffice,
      deliveryPhone: policy?.policyData?.deliveryPhone,
      deliveryName: policy?.policyData?.deliveryName,
      email: policy?.email,
      isOffline: duePayment?.isOffline
    };

    if (annex?._id) {
      initialValues = {
        policyId: policy._id,
        groupRequestId: policy?.groupRequestId,
        productId: policy?.productId,
        duePaymentId,
        annexId: annex._id,
        dueAmount: annex.amount || 0,
        paymentId: duePayment.paymentId,
        requestId: policy.requestId,
        site: policy.policyData.deliveryCity,
        // "До адрес на клиента" "До офис на куриерската фирма"
        pickupOffice,
        deliveryPhone: policy?.policyData?.deliveryPhone,
        deliveryName: policy?.policyData?.deliveryName,
        email: policy?.email,
        isOffline: annex.amount > 0
      };
    }

    const deliveryData = policy?.policyData?.deliveryData;
    if (deliveryData) {
      initialValues.site = deliveryData.cityDelivery;
      initialValues.deliveryPhone = deliveryData.phoneDelivery;
      initialValues.deliveryName = deliveryData.userDelivery;
      initialValues.deliveryMessage = deliveryData.messageDelivery;
    }

    this.setState(
      {
        loading: false,
        policy,
        courier: delivery,
        initialValues,
        duePaymentsCount: duePayments.length
      },
      () => {
        if (this.props?.isFromWizard && !this.state.hasDelivery) {
          this.props?.canCurrentStepContinue();
        }
      }
    );
  }

  onChangeCourier(e) {
    this.setState({
      courier: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  render() {
    const { policy, loading, initialValues, duePaymentsCount } = this.state;
    const { annex } = this.props;
    let deliveryAddress = policy?.policyData?.deliveryAddress;
    const deliveryData = policy?.policyData?.deliveryData;
    let deliveryWay = policy?.policyData?.deliveryWay;
    let deliveryPhone = policy?.policyData?.deliveryPhone;
    let deliveryName = policy?.policyData?.deliveryName;
    let deliveryCity = policy?.policyData?.deliveryCity;
    let deliveryMessage = policy?.policyData?.deliveryMessage;
    let deliveryPostalCodeSearch = "";
    let deliveryCitySearch = "";
    let officeDeliveryId = 0;
    let cityDeliveryId = 0;
    let officeDelivery = "";
    let officeDeliveryData = {};
    let postCode = 0;

    if (policy?.policyData?.deliveryData?.officeDeliverySpeedyData?.id) {
      officeDeliveryId = policy?.policyData?.deliveryData?.officeDeliverySpeedyData.id;
    } else if (policy?.policyData?.deliveryData?.officeDeliveryEcontData?.id) {
      officeDeliveryId = policy?.policyData?.deliveryData?.officeDeliveryEcontData.id;
    }

    if (policy?.policyData?.deliveryData?.cityDeliveryEcontData?.id) {
      cityDeliveryId = policy.policyData.deliveryData.cityDeliveryEcontData.id;
      postCode = policy.policyData.deliveryData.cityDeliveryEcontData.postCode;
    } else if (policy?.policyData?.deliveryData?.cityDeliverySpeedyData?.id) {
      cityDeliveryId = policy.policyData.deliveryData.cityDeliverySpeedyData.id;
      postCode = policy.policyData.deliveryData.cityDeliverySpeedyData.postCode;
    }

    if (deliveryData) {
      deliveryWay = deliveryData.deliveryType === 1 || deliveryData.deliveryType === 3 ? "ДО ОФИС" : "ДО АДРЕС";
      deliveryCity = deliveryData.cityDelivery;
      deliveryPhone = deliveryData.phoneDelivery;
      deliveryName = deliveryData.userDelivery;
      deliveryMessage = deliveryData.messageDelivery;
      if (deliveryData.deliveryType === 1) {
        deliveryAddress = deliveryData.officeDeliverySpeedy;
        officeDelivery = deliveryData.officeDeliverySpeedy;
        officeDeliveryData = deliveryData.officeDeliverySpeedyData;
      }
      if (deliveryData.deliveryType === 3) {
        deliveryAddress = deliveryData.officeDeliveryEcont;
        officeDelivery = deliveryData.officeDeliveryEcont;
        officeDeliveryData = deliveryData.officeDeliveryEcontData;
      }
      if (deliveryData.deliveryType === 2 || deliveryData.deliveryType === 4) {
        deliveryAddress = deliveryData.ulDelivery + " " || "";
        deliveryAddress += deliveryData.noDelivery + " " || "";
        deliveryAddress += deliveryData.blDelivery ? "бл:" + deliveryData.blDelivery + " " : "";
        deliveryAddress += deliveryData.vhDelivery ? "вх:" + deliveryData.vhDelivery + " " : "";
        deliveryAddress += deliveryData.etDelivery ? "ет:" + deliveryData.etDelivery + " " : "";
        deliveryAddress += deliveryData.apDelivery ? "ап:" + deliveryData.apDelivery + " " : "";
      }
      deliveryData.officeDelivery = officeDelivery;
      deliveryData.officeDeliveryData = officeDeliveryData;
    }

    if (deliveryCity !== undefined && deliveryCity) {
      if (deliveryCity.includes("[")) {
        let result = deliveryCity.match(/\[([\d]{4})\]?\s([\W]+)/);
        deliveryPostalCodeSearch = result[1];
        deliveryCitySearch = result[2];
      } else if (deliveryCity.includes(",")) {
        deliveryCitySearch = deliveryCity.split(",")[0];
      } else {
        deliveryCitySearch = deliveryCity;
      }
    }
    if (!postCode) {
      postCode = deliveryPostalCodeSearch;
    }
    const content = (
      <Grid item xs={12} sm={12} md={12}>
        {loading ? (
          <div style={{ color: "grey", fontSize: 14, textAlign: "center" }}>
            {loading && (
              <Typography gutterBottom variant="body1" component="span">
                <p>Зареждане...</p>
              </Typography>
            )}
            {loading && <CircularProgress size={20} />}
          </div>
        ) : (
          <>
            <Grid>
              <div style={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {this.props?.isFromWizard && (
                    <Grid item xs={12}>
                      <Grid container spacing={0} display="flex" justifyContent="center">
                        <FormGroup className="size-6 shipment-gray-box switch-padding has-delivery-switch">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!this.state.hasDelivery}
                                onChange={e => {
                                  this.setState(
                                    {
                                      hasDelivery: !e.target.checked
                                    },
                                    () => {
                                      if (!this.state.hasDelivery) {
                                        this.props?.canCurrentStepContinue();
                                      } else {
                                        this.props?.cantCurrentStepContinue();
                                      }
                                    }
                                  );
                                }}
                              />
                            }
                            label="Няма да се извършва доставка"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}
                  {this.state.hasDelivery && (
                    <>
                      <Grid item xs={5}>
                        <div className="size-6 shipment-details-box">
                          <Typography className="size-4 bold has-text-primary" sx={{ mb: 1 }}>
                            Детайли
                          </Typography>
                          <span className="bold">Застраховащ</span>: {policy.name}
                          <br />
                          <span className="bold">ЕГН</span>: {policy.egn}
                          <br />
                          <br />
                          <span className="bold">Куриер</span>: {policy.policyData.deliveryCompany}
                          <br />
                          <span className="bold">Начин на доставка</span>: {deliveryWay}
                          <br />
                          <span className="bold">Получател</span>: {deliveryName}
                          <br />
                          <span className="bold">Адрес</span>: {deliveryAddress}
                          <br />
                          <span className="bold">Населено място</span>: {deliveryCity}
                          <br />
                          <span className="bold">Телефон получател</span>: {deliveryPhone}
                          <br />
                          <span className="bold">Пощенски код</span>: {postCode}
                          <br />
                          Доп. пояснения: {deliveryMessage || "-"}
                          <br />
                          <br />
                          <span className="bold">Застраховка</span>: {policy.policyData.productName}
                          <br />
                          <span className="bold">Вноска</span>: {annex?._id ? "Анекс" : initialValues.paymentId / duePaymentsCount}
                          <br />
                          <span className="bold">Сума</span>: {initialValues.dueAmount}
                          {/*<br />*/}
                          {/*Полица: {policy._id}*/}
                          {/*<br />*/}
                          {/*Продукт: {policy.policyData.productName}*/}
                          {/*<br />*/}
                          {/*Застраховател: {policy.policyData.insurerName}*/}
                          {/*<br />*/}
                          {/*Телефон: {policy.phone}*/}
                          {/*<br />*/}
                          {/*Имейл: {policy.email}*/}
                          {/*<br />*/}
                          {/*От: {moment(policy.validFrom).locale("bg").format("DD.MM.YYYY")}*/}
                          {/*<br />*/}
                          {/*До: {moment(policy.validTo).locale("bg").format("DD.MM.YYYY")}*/}
                        </div>
                      </Grid>
                      <Grid item xs={7}>
                        {!this.props?.isFromWizard && <Typography className="size-5 has-text-centered">Подготви пратка</Typography>}
                        <div className="size-6" style={{ margin: "0 auto" }}>
                          {/* {isCashOnDeliveryMethod && ( */}
                          <Box mb={3} textAlign="center" className="shipment-gray-box">
                            <p className="size-4" style={{ margin: 0 }}>
                              <span className="size-4 bold ">Наложен платеж - </span>
                              <span className="size-4 bold has-text-primary">{`${initialValues.dueAmount.toFixed(2)}лв.`}</span>
                            </p>
                          </Box>
                          {/* )} */}
                          <form onSubmit={this.onSubmit}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Куриер</FormLabel>
                              <RadioGroup aria-label="courier" name="courier" value={this.state.courier} row onChange={this.onChangeCourier}>
                                <FormControlLabel value="Speedy" control={<Radio />} label="Speedy" />
                                <FormControlLabel value="Еконт" control={<Radio />} label="Еконт" />
                              </RadioGroup>
                            </FormControl>
                            <Collapse in={this.state.courier === "Speedy"}>
                              {this.state.courier === "Speedy" && (
                                <FormSpeedy
                                  initialValues={this.state.initialValues}
                                  selectedCity={deliveryCitySearch}
                                  selectedCityPostalCode={postCode}
                                  selectedAddress={deliveryAddress}
                                  officeDeliveryId={officeDeliveryId}
                                  deliveryData={deliveryData}
                                  cityDeliveryId={cityDeliveryId}
                                  canCurrentStepContinue={this.props?.isFromWizard ? this.props?.canCurrentStepContinue : () => {}}
                                />
                              )}
                            </Collapse>
                            <Collapse in={this.state.courier === "Еконт"}>
                              {this.state.courier === "Еконт" && (
                                <FormEcont
                                  initialValues={this.state.initialValues}
                                  selectedCity={deliveryCitySearch}
                                  selectedCityPostalCode={postCode}
                                  selectedAddress={deliveryAddress}
                                  officeDeliveryId={officeDeliveryId}
                                  deliveryData={deliveryData}
                                  cityDeliveryId={cityDeliveryId}
                                  canCurrentStepContinue={this.props?.isFromWizard ? this.props?.canCurrentStepContinue : () => {}}
                                />
                              )}
                            </Collapse>
                          </form>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
    if (_get(this.props, "params.id")) {
      return (
        <PageLayout title="Пратка" {...this.props}>
          {content}
        </PageLayout>
      );
    }

    return content;
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(withParams(Shipment));
