import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInstallmentsCount, getPoliciesCount } from "../../services/PoliciesService";
import { countQueriesKeys } from "../../config/countConfig";
const productIds = ["GO", "CSC", "PRP"]; // todo ako искаме да добавим и за други продукти

export const fetchPoliciesCount = createAsyncThunk("count/fetch", async () => {
  let counts = {};
  for (const productId of productIds) {
    counts[productId] = {};
  }
  const installmentsResponse = await getInstallmentsCount({ variant: "confirmed", products: productIds });
  const policiesResponse = await getPoliciesCount(countQueriesKeys);
  if (policiesResponse?.data) {
    counts = { ...counts, ...policiesResponse.data };
  }
  for (const productId of productIds) {
    counts[productId]["installments.confirmed"] = installmentsResponse?.data[productId]?.count || 0;
  }

  return counts;
});

const policiesCountSlice = createSlice({
  name: "count",
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPoliciesCount.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export default policiesCountSlice.reducer;
