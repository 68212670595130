import moment from "moment";
import _get from "lodash.get";

export const validateSum = text => {
  if (!text || (text && !text.trim())) {
    return true;
  }
  const rgx = /^[0-9]*\.?[0-9]*$/;
  return !!text.trim().match(rgx);
};
export const validatePhone = text => {
  const reg = /^\d{10}$/;
  return reg.test(text.trim());
};

export const validateBgPhoneNumberWith359 = text => {
  const regMobile = /^(?:0|\+359)(8|9)\d{8}$/;
  if (text && text.length > 0 && regMobile.test(text.trim()) === false) {
    return false;
  }
  return true;
};

export const checkTalon = text => {
  const reg = /^[0-9]{9}$/;
  return !(text && text.length && reg.test(text.replace(/\s/g, "")) === false);
};

export const formatDeliveryAddress = deliveryData =>
  `${deliveryData.ulDelivery || ""}${deliveryData.noDelivery ? ` ${deliveryData.noDelivery}` : ""}${deliveryData.blDelivery ? ` бл:${deliveryData.blDelivery}` : ""}${
    deliveryData.vhDelivery ? ` вх:${deliveryData.vhDelivery}` : ""
  }${deliveryData.etDelivery ? ` eт:${deliveryData.etDelivery}` : ""}${deliveryData.apDelivery ? ` ап:${deliveryData.apDelivery}` : ""}`;

const EGN_WEIGHTS = [2, 4, 8, 5, 10, 9, 7, 3, 6];

const LNCH_WEIGHTS = [21, 19, 17, 13, 11, 9, 7, 3, 1];

export const validateEGN = text => {
  if (text && text.length === 10) {
    /* Calculate checksum */
    var egnsum = 0;
    var lnchsum = 0;
    for (var i = 0; i < 9; i++) {
      var numberChar = Number(text.charAt(i));
      if (numberChar >= 0) {
        egnsum += numberChar * EGN_WEIGHTS[i];
        lnchsum += numberChar * LNCH_WEIGHTS[i];
      }
    }
    let valid_checksum = egnsum % 11;
    let valid_lnchsum = lnchsum % 10;
    if (valid_checksum === 10) {
      valid_checksum = 0;
    }
    let isValid = valid_checksum === Number(text.charAt(9));
    if (!isValid) {
      isValid = valid_lnchsum === Number(text.charAt(9));
    }
    return isValid;
  } else if (text) {
    return false;
  }
  return true;
};
export const validateDate = text => {
  if (text) {
    const formattedDate = moment(text, "DD-MM-YYYY", true);
    if (formattedDate.isValid()) {
      const isAfter = formattedDate ? formattedDate.isAfter("1980-01-01", "day") : false;
      const isBefore = formattedDate ? formattedDate.isBefore(moment().add(1, "day"), "day") : false;
      return isAfter && isBefore;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const FIRST_SUM_9DIGIT_WEIGHTS = [1, 2, 3, 4, 5, 6, 7, 8];
const SECOND_SUM_9DIGIT_WEIGHTS = [3, 4, 5, 6, 7, 8, 9, 10];
const FIRST_SUM_13DIGIT_WEIGHTS = [2, 7, 3, 5];
const SECOND_SUM_13DIGIT_WEIGHTS = [4, 9, 5, 7];
const calculateNinthDigitInEIK = digits => {
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum = sum + digits[i] * FIRST_SUM_9DIGIT_WEIGHTS[i];
  }
  const remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 0; i < 8; i++) {
    secondSum = secondSum + digits[i] * SECOND_SUM_9DIGIT_WEIGHTS[i];
  }
  let secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  return 0;
};

const calculateThirteenthDigitInEIK = digits => {
  const ninthDigit = calculateNinthDigitInEIK(digits);
  if (ninthDigit !== digits[8]) {
    return "Incorrect 9th digit in EIK-13.";
  }
  let sum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    sum = sum + digits[i] * FIRST_SUM_13DIGIT_WEIGHTS[j];
  }
  let remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    secondSum = secondSum + digits[i] * SECOND_SUM_13DIGIT_WEIGHTS[j];
  }
  const secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  return 0;
};
export const validateEIK = text => {
  const reg = /^\d+$/;
  const eik = text.trim();
  if (eik && (eik.length === 9 || eik.length === 13) && reg.test(eik)) {
    if (eik.length === 9) {
      const digits = Array.from(eik).map(el => parseInt(el));
      const ninthDigit = calculateNinthDigitInEIK(digits);
      return ninthDigit === digits[8];
    } else {
      const digits = Array.from(eik).map(el => parseInt(el));
      const thirteenDigit = calculateThirteenthDigitInEIK(digits);
      return thirteenDigit === digits[12];
    }
  }
  return false;
};
export const validateEmail = email => {
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  return reg.test(email.trim());
};

export const validateEmailsSeparatedByComma = email => {
  let reg = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}(?:,\s*[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,})*$/;
  return reg.test(email.trim()) || email === "";
};

export const validateName = name => {
  let reg = /^[а-я\s-]+$/i;
  return reg.test(name.trim());
};
export const checkCyrillicAndAllowNumbers = text => {
  let reg = /^[а-я\s-]+$/i;
  const textWithoutNumbers = text.replace(/[0-9]/g, "");
  if (textWithoutNumbers && textWithoutNumbers.length > 0 && reg.test(textWithoutNumbers) === false) {
    return false;
  } else {
    return true;
  }
};

export const validateEIKOrEgn = text => {
  if (validateEGN(text) === true) {
    return true;
  } else return validateEIK(text) === true;
};

export const checkCardIsExpired = expiry => {
  const expiryArr = expiry.match(/.{1,2}/g);
  const lastDayOfExpirationDate = moment(`${_get(expiryArr, "[0]")}.${_get(expiryArr, "[1]")}`, "MM.YYYY").daysInMonth();
  const expirationDate = moment(`${lastDayOfExpirationDate}.${_get(expiryArr, "[0]")}.${_get(expiryArr, "[1]")}`, "DD.MM.YYYY");
  const diffDays = expirationDate.diff(moment(), "days");
  return diffDays < 0;
};

export const getInsurerName = code => {
  switch (code) {
    case "allianz":
      return "Алианц";
    case "armeec":
      return "Армеец";
    case "bulins":
      return "Бул Инс";
    case "bulstrad":
      return "Булстрад";
    case "bulstradlife":
      return "Булстрад Живот";
    case "dallbogg":
      return "ДаллБогг";
    case "dzi":
      return "ДЗИ";
    case "euroins":
      return "Евроинс";
    case "generali":
      return "Дженерали";
    case "instinct":
      return "Инстинкт";
    case "levins":
      return "Лев Инс";
    case "ozk":
      return "ОЗК";
    case "uniqa":
      return "Уника";
    case "coface":
      return "Coface";
    case "colonnade":
      return "Colonnade";
    default:
      return "";
  }
};

export const determineFuelType = fuelTypeId => {
  if (fuelTypeId === "1") {
    return "Бензин";
  } else if (fuelTypeId === "2") {
    return "Дизел";
  } else if (fuelTypeId === "3") {
    return "Газ";
  } else if (fuelTypeId === "4") {
    return "Бензин/Водород";
  } else if (fuelTypeId === "5") {
    return "Дизел/Природен Газ";
  } else if (fuelTypeId === "6") {
    return "Дизел/Електричество";
  } else if (fuelTypeId === "7") {
    return "Природен Газ";
  } else if (fuelTypeId === "8") {
    return "Електродвигател";
  } else if (fuelTypeId === "9") {
    return "Биогориво";
  } else if (fuelTypeId === "10") {
    return "Водородно гориво";
  } else if (fuelTypeId === "11") {
    return "Бензин/Е85";
  } else if (fuelTypeId === "12") {
    return "Втечнен Нефтен Газ";
  } else if (fuelTypeId === "13") {
    return "Бензин/Електричество";
  } else if (fuelTypeId === "14") {
    return "Бензин/Втечнен Нефтен Газ";
  } else if (fuelTypeId === "15") {
    return "Бензин/Газ";
  } else if (fuelTypeId === "16") {
    return "Бензин/Природен Газ";
  }
};

export const determineVehicleType = typeId => {
  if (typeId === "1") {
    return "ЛЕК АВТОМОБИЛ";
  } else if (typeId === "5") {
    return "АВТОБУС";
  } else if (typeId === "8") {
    return "ТОВАРЕН АВТОМОБИЛ";
  } else {
    return "ДРУГ";
  }
};

export const vehicleCategoryToText = category => {
  const table = {
    M1: "Лек автомобил",
    M2: "Автобуси",
    M3: "Автобуси",
    L1: "Мотоциклети и АТВ",
    N1: "Товарен автомобил",
    N2: "Товарен автомобил",
    N3: "Товарен автомобил",
    O1: "Ремаркета и полуремаркета"
  };
  return table[category] || "";
};

export const vehicleTypeCodeToText = code => {
  const table = {
    "001": "АВТОБУС",
    "002": "ВЛЕКАЧ",
    "003": "ЕЛЕКТРОКАР",
    "004": "КОЛЕСАР",
    "005": "КОЛЕСЕН ТРАКТОР",
    "006": "ЛЕК АВТОМОБИЛ",
    "007": "МОТОКАР",
    "008": "МОТОПЕД",
    "009": "МОТОТРИКОЛКА ПЪТНИЧЕСКА",
    "010": "МОТОТРИКОЛКА ТОВАРНА",
    "011": "МОТОЦИКЛЕТ",
    "012": "МОТОЦИКЛЕТ С КОШ",
    "013": "ПОЛУРЕМАРКЕ",
    "014": "РЕМАРКЕ ЗА ЛЕК АВТОМОБИЛ",
    "015": "РЕМАРКЕ ЗА ТОВАРЕН АВТОМОБИЛ",
    "016": "САМОХОДНО ШАСИ",
    "017": "СПЕЦИАЛЕН АВТОМОБИЛ",
    "018": "СПЕЦИАЛИЗИРАНА МАШИНА(ДРУГА)",
    "019": "ТОВАРЕН АВТОМОБИЛ",
    "021": "ТРИКОЛЕСНО ПС",
    "022": "ЧЕТИРИКОЛЕСНО ПС",
    "023": "ТРАМВАЙ",
    "024": "ТРОЛЕЙБУС",
    "025": "ВЕРИЖЕН ТРАКТОР",
    "026": "СПЕЦИАЛИЗИРАНА САМОХОДНА МАШИНА(БАГЕР)",
    "027": "ТРАКТОРНО РЕМАРКЕ",
    "028": "ГОРСКА ТЕХНИКА",
    "029": "ЗЪРНОКОМБАЙН",
    "030": "САМОХОДЕН СИЛАЖОКОМБАЙН",
    "031": "ДРУГА САМОХОДНА МАШИНА (КОСАЧКА)",
    "032": "ВЪЖЕНИ ЛИНИИ И ВЛЕКОВЕ",
    "033": "МОРСКИ И ПЛАВАТЕЛНИ СЪДОВЕ",
    "034": "ВЪЗДУХОПЛАВАТЕЛНИ СРЕДСТВА"
  };
  return table[code] || "";
};

export const required = value => {
  if (typeof value === "object") {
    return !!value;
  }
  if (typeof value === "number") {
    if (value === 0) {
      return true;
    }
    return !!value;
  } else {
    const trimmedValue = value ? value.trim() : "";
    return !!trimmedValue;
  }
};
export const checkValidDateRange = value => {
  const { from, to } = value || {};
  return !!(from && to && to?.isAfter(from?.format("YYYY-MM-DD"), "day"));
};

export const stickerIsValid = value => {
  return (value.length === 8 || value.length === 9) && !/\D/.test(value);
};
export const greenCardIsValid = value => {
  return value.length === 6 && !/\D/.test(value);
};
export const prefixIsValid = value => {
  return value.length === 2 && /\D/.test(value) && value === value.toUpperCase();
};
export const dateFromIsValid = value => {
  const date = moment(value, "DD-MM-YYYY", true);
  return date.isValid();
};
export const countriesFullNameView = (countryCodes, countriesJson) =>
  countriesJson.countries
    .filter(country => countryCodes.includes(country.iso))
    .map(country => country.name["bg"])
    .join(", ");

export const validateGONumber = goNumber => {
  let reg = /^BG+\//;
  return reg.test(goNumber.trim());
};

export const calculateBirthDateByEgn = egn => {
  // console.log("egn", egn);
  // calculate birth date by egn
  const dd = egn.substr(4, 2);
  let mm = egn.substr(2, 2);
  let yy = parseInt(egn.substr(0, 2), 10);
  if (mm > 40) {
    yy += 2000;
    mm -= 40;
  } else if (mm > 20) {
    yy += 1800;
    mm -= 20;
  } else {
    yy += 1900;
  }

  return moment()
    .year(yy)
    .month(mm - 1)
    .date(dd)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const validateBgPhoneNumber = text => {
  const regMobile = /^[0]{0,1}(?:8|9)\d{8}$/;
  if (text && text.length > 0 && regMobile.test(text.trim()) === false) {
    return false;
  }
  return true;
};

export const formatBGPhone = text => {
  let phone;
  if (text) {
    phone = text.replace(/\s/g, "").replace(/-/g, "").replace("+359", "");
    // remove leading 0
    if (phone.startsWith("0") && phone.length === 10) {
      phone = phone.slice(1);
    }

    if (validateBgPhoneNumber(phone)) {
      return phone;
    }
  }
  // remove leading 0

  return "";
};

export const validateNumberFrom = (value, props) => {
  if (!value) {
    return true;
  }
  const type = props?.formData?.type;
  let reg = /^\d{8,9}$/;

  if (type === "goSticker") reg = /^\d{8,9}$/;
  if (type === "goGreenCard") reg = /^\d{6}$/;

  return reg.test(value.trim());
};

export const validateNumberTo = (value, props) => {
  if (!value) {
    return true;
  }
  const from = props?.formData?.from;
  const type = props?.formData?.type;
  if (parseInt(from) >= parseInt(value)) {
    return false;
  }
  let reg = /^\d{8,9}$/;
  if (type === "goSticker") reg = /^\d{8,9}$/;
  if (type === "goGreenCard") reg = /^\d{6}$/;
  return reg.test(value.trim());
};

const transliterateRegNo = text => {
  let rep;
  if (text) {
    rep = text.toUpperCase().replace(/\s/g, "");
  } else {
    return "";
  }

  rep = rep
    .replace(/В/g, "B")
    .replace(/в/g, "B")
    .replace(/А/g, "A")
    .replace(/а/g, "A")
    .replace(/Е/g, "E")
    .replace(/е/g, "E")
    .replace(/К/g, "K")
    .replace(/к/g, "K")
    .replace(/М/g, "M")
    .replace(/м/g, "M")
    .replace(/Н/g, "H")
    .replace(/н/g, "H")
    .replace(/О/g, "O")
    .replace(/о/g, "O")
    .replace(/Р/g, "P")
    .replace(/р/g, "P")
    .replace(/С/g, "C")
    .replace(/с/g, "C")
    .replace(/Т/g, "T")
    .replace(/т/g, "T")
    .replace(/У/g, "Y")
    .replace(/у/g, "Y")
    .replace(/Х/g, "X")
    .replace(/х/g, "X");
  return rep;
};

export const checkRegNumber = text => {
  if (text) {
    const regNo = transliterateRegNo(text);
    const reg = /^[ABEKMHOPCTYX]{1,2}[0-9ABEKMHOPCTYX]{4}[ABEKMHOPCTYX0-9]{1,2}$/;
    return !(regNo && regNo.length > 0 && reg.test(regNo.replace(/\s/g, "")) === false);
  }
  return false;
};

export const getYearsByEgn = egn => moment().diff(calculateBirthDateByEgn(egn), "years");

export const getBase64 = function (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};
