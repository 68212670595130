import React, { useState } from "react";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import _get from "lodash.get";
import withValidation from "../../../../HOC/withValidation";
import { txtFields } from "../../../../../config/ManualPolicyIssuanceCSC.page.config";
import { setWizardStatus, updatePolicyDuringIssuance, uploadPolicyFile } from "../../../../../services/PoliciesService";
import { gray, grayBorder, grayLight, primary } from "../../../../../utils/colors";
import { error as errorAlert, success as successAlert } from "../../../../../utils/notifications";
import WizardButton from "../../../common/WizardButton";

const ManualPolicyIssuance = props => {
  const { objWithEgnAndRequestId, subVariant } = props;
  const dateFromDefault = _get(objWithEgnAndRequestId, "policyData.validFrom");
  const dateToDefault = _get(objWithEgnAndRequestId, "policyData.validTo");
  const variant = props?.variant;
  const [dateFrom, setDateFrom] = useState(dateFromDefault ? moment(dateFromDefault).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"));
  const [dateTo, setDateTo] = useState(dateToDefault ? moment(dateToDefault).format("DD-MM-YYYY") : moment().add(1, "years").format("DD-MM-YYYY"));
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const handleDateChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    const isValid = moment(value, "DD-MM-YYYY", true).isValid();
    setDateFrom(value);
    if (isValid) {
      setDateTo(moment(value, "DD-MM-YYYY").add(1, "years").format("DD-MM-YYYY"));
    } else {
      setDateTo("");
    }
  };
  const onFileChange = e => {
    if (e.target.files[0].size > 1024 * 1024 * 10) {
      errorAlert("Размерът на файла не може да е по-голям от 10 MB.");
      setFile(null);
      return false;
    } else {
      setFile(e?.target?.files[0]);
      setFileName(e?.target?.files[0]?.name);
    }
  };
  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      setLoading(true);
      const { nextStep, refreshData } = props;
      const dateFromFormatted = dateFrom ? moment(dateFrom, "DD-MM-YYYY").format("YYYY-MM-DD") : dateFrom;
      await updatePolicyDuringIssuance({
        date: dateFromFormatted,
        policyNumber: props?.formData?.policyNumber?.trim(),
        policy: props?.objWithEgnAndRequestId,
        isFromWizard: true
      });
      await uploadPolicyFile({ file, policy: props?.objWithEgnAndRequestId, isFromWizard: true });

      await setWizardStatus({ policyDBId: props?.objWithEgnAndRequestId?._id, wizardStatus: props?.wizardStatus });
      refreshData();
      nextStep();
      successAlert("policy number and date set successfully!");
      setLoading(false);
    }
  };
  //todo DA GO NAPRAVQ KATO AutomaticPolicyIssuance.js
  return (
    <>
      <span className="red size-6" style={{ marginLeft: 14, marginBottom: 5, display: "inline-block" }}>
        В сила от:
      </span>
      <TextField
        fullWidth
        label="Въведи датата"
        placeholder="24-01-2020"
        id="outlined-size-small-date-from"
        variant="outlined"
        size="small"
        value={dateFrom}
        onChange={handleDateChange}
      />
      <br />
      <br />
      {variant === "queries" && (
        <>
          {txtFields.map(f => (
            <TextField
              sx={{ mb: 1, mt: 1 }}
              key={f.name}
              fullWidth
              label={f.label}
              placeholder={f.placeholder}
              variant="outlined"
              size="small"
              name={f.name}
              value={props?.formData[f.name]}
              onChange={props?.handleChange}
              helperText={props?.errors[f.name]}
              error={!!props?.errors[f.name]}
            />
          ))}
          <TextField
            label=""
            id="outlined-start-adornment"
            readOnly
            style={{ width: "100%" }}
            value={fileName}
            placeholder="Няма избран файл"
            inputProps={{
              style: {
                padding: 9,
                color: gray
              }
            }}
            InputProps={{
              sx: {
                padding: 0,
                mb: 1,
                mt: 2
              },
              endAdornment: (
                <InputAdornment sx={{ height: "100%" }} position="end">
                  <Button
                    variant="contained"
                    color="inherit"
                    component="label"
                    sx={{ ml: 1, py: "8px", backgroundColor: grayLight, boxShadow: "none", borderLeft: `1px solid ${grayBorder}` }}
                  >
                    Прикачи файл
                    <input type="file" hidden onChange={onFileChange} />
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </>
      )}
      <WizardButton disabled={!file || loading} handleSubmit={handleSubmit} loading={loading} btnText="Запази" />
    </>
  );
};

export default withValidation(ManualPolicyIssuance, { txtFields });
