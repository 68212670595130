import React, { useState } from "react";
import { rolesMatched } from "../PrivateRoute/PrivateRoute";
import _get from "lodash.get";
import ActionsList from "./ActionsList";

const actionsConfig = {
  CSC: {
    policies: {
      queries: {
        query: ["sendOffer", "changeStatusPolicy", "riskyUserProfile"],
        offers: ["sendOffer", "changeStatusPolicy", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "incorrectPayers", "riskyUserProfile", "editPolicy"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        issued: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendDocuments", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendDocuments", "riskyUserProfile", "addAnnex"],
        expiring: ["changeStatusPolicy", "sendDocuments", "riskyUserProfile"],
        informed: ["changeStatusPolicy", "sendDocuments", "riskyUserProfile"],
        renewed: ["changeStatusPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "incorrectPayers", "sendDocuments", "riskyUserProfile"],
        all: ["changeStatusPolicy", "checkGOStatus", "editPolicy", "incorrectPayers", "riskyUserProfile", "sendDocuments", "addAnnex"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "riskyUserProfile"],
        informed: ["changeStatus", "riskyUserProfile"],
        overdue: ["changeStatus", "riskyUserProfile"],
        confirmed: ["refund", "editPolicy", "changeStatus", "riskyUserProfile"],
        issued: ["refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile", "sendDocuments"],
        cancelled: ["changeStatus", "riskyUserProfile"], //todo
        paidElsewhere: ["changeStatus", "riskyUserProfile"],
        rejected: ["changeStatus", "riskyUserProfile"], //todo
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"] //todo
      }
    }
  },
  GO: {
    policies: {
      queries: ["refund", "changeStatusPolicy", "editPolicy", "checkGOStatus", "riskyUserProfile", "addAnnex"],
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "sendDocuments", "cancelPolicy", "riskyUserProfile", "addAnnex"],
        expiring: ["changeStatusPolicy", "checkGOStatus", "riskyUserProfile"],
        renewed: ["changeStatusPolicy", "riskyUserProfile"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile", "changeStatusPolicy"],
        all: ["changeStatusPolicy", "checkGOStatus", "editPolicy", "incorrectPayers", "riskyUserProfile", "shipment", "addAnnex"]
      }
    },
    installments: {
      installments: {
        confirmed: ["changeStatus", "refund", "checkGOStatus", "editPolicy", "riskyUserProfile"],
        issued: ["changeStatus", "refund", "editPolicy", "sendDocuments", "cancelPolicy", "incorrectPayers", "riskyUserProfile"],
        process: ["changeStatus", "checkGOStatus", "riskyUserProfile"],
        informed: ["changeStatus", "checkGOStatus", "riskyUserProfile"],
        overdue: ["changeStatus", "checkGOStatus", "riskyUserProfile"],
        cancelled: ["changeStatus", "riskyUserProfile"],
        paidElsewhere: ["changeStatus", "refund", "riskyUserProfile"],
        all: ["changeStatus", "refund", "checkGOStatus", "editPolicy", "sendDocuments", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  TRA: {
    policies: {
      queries: ["refund", "editPolicy", "riskyUserProfile"],
      policies: {
        active: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"],
        cancelled: ["refund", "riskyUserProfile", "downloadPolicyPdf"],
        all: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"]
      }
    }
  },
  PRP: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "sendDocuments", "cancelPolicy", "riskyUserProfile"],
        expiring: ["editPolicy", "cancelPolicy", "changeStatusPolicy", "riskyUserProfile"],
        expired: ["editPolicy", "riskyUserProfile"],
        informed: ["editPolicy", "riskyUserProfile"],
        renewed: ["refund", "editPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "riskyUserProfile"],
        all: ["editPolicy", "refund", "editPolicy", "sendDocuments", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        issued: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        confirmed: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        process: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        informed: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        overdue: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        cancelled: ["refund", "changeStatus", "riskyUserProfile"],
        all: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    }
  },
  HLT: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "sendDocuments", "cancelPolicy", "riskyUserProfile"],
        expiring: ["editPolicy", "cancelPolicy", "changeStatusPolicy", "riskyUserProfile"],
        expired: ["editPolicy", "riskyUserProfile"],
        informed: ["editPolicy", "riskyUserProfile"],
        renewed: ["refund", "editPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "riskyUserProfile"],
        all: ["editPolicy", "refund", "editPolicy", "sendDocuments", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        issued: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        process: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        informed: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        overdue: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        cancelled: ["refund", "changeStatus", "riskyUserProfile"],
        all: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    }
  },
  CHLT: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "sendDocuments", "cancelPolicy", "riskyUserProfile"],
        expiring: ["editPolicy", "cancelPolicy", "changeStatusPolicy", "riskyUserProfile"],
        expired: ["editPolicy", "riskyUserProfile"],
        informed: ["editPolicy", "riskyUserProfile"],
        renewed: ["refund", "editPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "riskyUserProfile"],
        all: ["editPolicy", "refund", "editPolicy", "sendDocuments", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        issued: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        process: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        informed: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        overdue: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        cancelled: ["refund", "changeStatus", "riskyUserProfile"],
        all: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    }
  },
  MED: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "sendDocuments", "cancelPolicy", "riskyUserProfile"],
        expiring: ["editPolicy", "cancelPolicy", "riskyUserProfile"],
        expired: ["editPolicy", "riskyUserProfile"],
        informed: ["editPolicy", "riskyUserProfile"],
        renewed: ["refund", "editPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "riskyUserProfile"],
        all: ["editPolicy", "refund", "editPolicy", "sendDocuments", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        issued: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        process: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        informed: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        overdue: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        cancelled: ["refund", "changeStatus", "riskyUserProfile"],
        all: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    }
  },
  OFF: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "sendDocuments", "cancelPolicy", "riskyUserProfile"],
        expiring: ["editPolicy", "cancelPolicy", "riskyUserProfile"],
        expired: ["editPolicy", "riskyUserProfile"],
        informed: ["editPolicy", "riskyUserProfile"],
        renewed: ["refund", "editPolicy", "sendDocuments", "riskyUserProfile"],
        cancelled: ["refund", "riskyUserProfile"],
        all: ["editPolicy", "refund", "editPolicy", "sendDocuments", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        issued: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        process: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        informed: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        overdue: ["changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"],
        cancelled: ["refund", "changeStatus", "riskyUserProfile"],
        all: ["refund", "changeStatus", "editPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    }
  },
  MTN: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"],
        cancelled: ["refund", "riskyUserProfile", "downloadPolicyPdf"],
        all: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"]
      }
    }
  },
  EXT: {
    policies: {
      policies: {
        active: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"],
        cancelled: ["refund", "riskyUserProfile", "downloadPolicyPdf"],
        all: ["refund", "editPolicy", "cancelPolicy", "sendDocuments", "riskyUserProfile", "downloadPolicyPdf"]
      }
    }
  },
  LFE: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  LFI: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  HWP: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  HCL: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  HCEF: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  CHCL: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  CI: {
    policies: {
      queries: {
        query: ["changeStatusPolicy", "refund", "resendToEvrotrust", "riskyUserProfile"],
        offers: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        confirmed: ["changeStatusPolicy", "refund", "sendForEvrotrustSigning", "riskyUserProfile"],
        rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"],
        signedByCustomer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"],
        sentToInsurer: ["changeStatusPolicy", "refund", "sendDocumentsToInsurer", "sendForEvrotrustSigning", "riskyUserProfile"]
      },
      policies: {
        active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendPolicy", "sendDocumentsToInsurer", "riskyUserProfile", "sendForEvrotrustSigning"],
        cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["refund", "editPolicy", "cancelPolicy", "cancelPolicy", "riskyUserProfile"]
      }
    },
    installments: {
      installments: {
        process: ["changeStatus", "stopPayments", "riskyUserProfile"],
        overdue: ["changeStatus", "stopPayments", "riskyUserProfile"],
        informed: ["changeStatus", "stopPayments", "riskyUserProfile"],
        rejected: ["refund", "incorrectPayers", "riskyUserProfile"],
        all: ["changeStatus", "refund", "editPolicy", "cancelPolicy", "incorrectPayers", "riskyUserProfile"]
      }
    }
  },
  ["CSC+GO"]: {
    CSC: {
      policies: {
        queries: ["changeStatusPolicy", "riskyUserProfile"],
        offers: {
          query: ["sendOffer", "changeStatusPolicy", "riskyUserProfile"],
          offers: ["sendOffer", "changeStatusPolicy", "riskyUserProfile"],
          confirmed: ["changeStatusPolicy", "refund", "incorrectPayers", "riskyUserProfile"],
          rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"]
        },
        policies: {
          active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendDocuments", "riskyUserProfile"],
          cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
          all: ["refund", "changeStatusPolicy", "checkGOStatus", "editPolicy", "incorrectPayers", "riskyUserProfile", "sendDocuments"]
        }
      }
    },
    GO: {
      policies: {
        queries: ["refund", "changeStatusPolicy", "checkGOStatus", "riskyUserProfile"],
        offers: {
          query: ["changeStatusPolicy", "checkGOStatus", "riskyUserProfile"],
          offers: ["changeStatusPolicy", "checkGOStatus", "riskyUserProfile"],
          confirmed: ["changeStatusPolicy", "refund", "incorrectPayers", "riskyUserProfile"],
          rejected: ["changeStatusPolicy", "refund", "riskyUserProfile"]
        },
        policies: {
          active: ["refund", "editPolicy", "incorrectPayers", "cancelPolicy", "sendDocuments", "riskyUserProfile"],
          cancelled: ["refund", "incorrectPayers", "riskyUserProfile"],
          all: ["refund", "changeStatusPolicy", "checkGOStatus", "editPolicy", "incorrectPayers", "riskyUserProfile", "sendDocuments"]
        }
      }
    }
  }
};

const isActionVisible = (actionId, productId, type, variant, subVariant, currentProduct) => {
  const configArr = _get(actionsConfig, `${currentProduct === "CSC+GO" ? `${currentProduct}.` : ""}${productId}.${type}.${variant}${subVariant ? `.${subVariant}` : ""}`, []);
  return configArr.includes(actionId);
};
const Actions = props => {
  const { roles, policy, handleAction, type, variant, subVariant, refreshData, currentProduct } = props;
  let paymentMode;
  if (variant === "installments") {
    paymentMode = _get(policy, "duePayment.isOffline") ? 2 : 1;
  } else {
    paymentMode = _get(policy, "policyData.paymentMode");
  }
  const isCancelled = _get(policy, "isCancelled");
  const isPending = _get(policy, "isPending");
  const productId = _get(policy, "productId");
  const isVisible = actionId => isActionVisible(actionId, productId, type, variant, subVariant, currentProduct);

  const toProcess = _get(policy, "toProcess");

  const getOptions = () => {
    return [
      {
        id: "editPolicy",
        name: "Промяна на данни",
        hidden: !isVisible("editPolicy") || isCancelled,
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "changeStatusPolicy",
        name: "Промяна на статус",
        hidden: !isVisible("changeStatusPolicy"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "createPolicy",
        name: "Издаване на Каско !!!",
        hidden: !isVisible("createPolicy") || (productId !== "CSC" && !isPending),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "createGOPolicy", // todo за момента не се използва никъде
        name: "Издаване",
        hidden: !isVisible("createGOPolicy") || !(productId === "GO" && isPending) || (_get(policy, "policyData.goPolicyNumber") && _get(policy, "policyData.goDueDate")),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "payInstallment",
        name: "Издаване",
        hidden: !isVisible("payInstallment") || !(productId === "GO" && isPending && _get(policy, "policyData.goPolicyNumber") && _get(policy, "policyData.goDueDate")),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "incorrectPayers",
        name: "Некоректен платец",
        data: policy,
        hidden: !isVisible("incorrectPayers"),
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "riskyUserProfile",
        name: "Рисков профил",
        hidden: !isVisible("riskyUserProfile"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "shipment",
        name: "Пратка",
        hidden: !isVisible("shipment") || isCancelled,
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "sendDocuments",
        name: "Изпрати документи",
        hidden: !isVisible("sendDocuments"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "sendOffer",
        name: "Изпрати оферта",
        hidden: !isVisible("sendOffer") || isCancelled,
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "cancelPolicy",
        name: "Прекратяване на полица",
        hidden: !isVisible("cancelPolicy"),
        disabled: isCancelled,
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },

      {
        id: "changeStatus",
        name: "Промяна на статус",
        hidden: !isVisible("changeStatus"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "payInstallment",
        name: "Плати вноска",
        disabled: isCancelled,
        hidden: !isVisible("payInstallment") || !(productId === "GO"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "updateDateOfDuePayment",
        name: "Дата на плащане",
        hidden: !isVisible("updateDateOfDuePayment"),
        disabled: !toProcess,
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "checkGOStatus",
        name: "Проверка ГФ",
        hidden: !isVisible("checkGOStatus"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "refund",
        name: "Refund",
        disabled: paymentMode === 2 || paymentMode === 3 || productId === "OFF",
        hidden: !isVisible("refund"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "sendDocumentsToInsurer",
        name: "Изпрати на застрахователя",
        hidden: !isVisible("sendDocumentsToInsurer"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "sendForEvrotrustSigning",
        name: "Изпрати за подпис в Евротръст",
        hidden: !isVisible("sendForEvrotrustSigning"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "resendToEvrotrust",
        name: "Повторно изпращане към Евротръст",
        hidden: !isVisible("resendToEvrotrust"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      },
      {
        id: "sendPolicy",
        name: "Изпрати полица",
        hidden: !isVisible("sendPolicy"),
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: false
      },
      {
        id: "addAnnex",
        name: "Добави анекс",
        hidden: !isVisible("addAnnex"),
        data: policy,
        handleAction: async option => await handleAction(policy, { hasModal: true, id: "addAnnexWizard" }, refreshData, { getAnnex: true }),
        isAsync: true,
        hasModal: true
      },
      {
        id: "downloadPolicyPdf",
        name: "Свали PDF от Евроинс",
        hidden: !isVisible("downloadPolicyPdf") || _get(policy, "insurerId") !== "euroins",
        data: policy,
        handleAction: option => handleAction(policy, option, refreshData),
        hasModal: true
      }
    ];
  };
  return <ActionsList options={getOptions()} />;
};

export default Actions;
