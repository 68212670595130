import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/toastify.scss";
import Routes from "./routes/Routes";
import { useSelector, useDispatch } from "react-redux";
// import { selectUser, selectIsAuthenticated } from "./store/reducers/auth";
import { selectUser } from "./store/reducers/auth";
import { LinearProgress, CssBaseline } from "@mui/material";
import { fetchPoliciesCount } from "./store/reducers/count";
const App = () => {
  // const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    // Create a new Shared Worker
    const worker = new SharedWorker("/sharedworker.js", { name: "Worker1" });
    const port = worker.port;

    port.onmessage = event => {
      const message = event.data;
      location.reload();
    };
    return () => {
      port.close();
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    dispatch(fetchPoliciesCount()); // при първо зареждане на апп-а колва

    const intervalId = setInterval(() => {
      dispatch(fetchPoliciesCount()); // колва на 5 минути
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [user, dispatch]);

  return (
    <React.Fragment>
      <CssBaseline />
      {user !== undefined ? (
        <>
          <Routes />
        </>
      ) : (
        <div style={{ margin: "26px 20px 0 20px" }}>
          <LinearProgress />
        </div>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
