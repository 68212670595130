import React from "react";
import _get from "lodash.get";
import { getYearsByEgn } from "../../utils/functions";
import moment from "moment";

const TitularAndInsuredPersons = props => {
  const { policy } = props;
  const productId = _get(policy, "productId");
  const persons = _get(props, "policy.policyData.persons", []);
  const adults = _get(props, "policy.policyData.adults");
  const children = _get(props, "policy.policyData.children");
  const firstName = _get(props, "policy.policyData.firstName");
  const middleName = _get(props, "policy.policyData.middleName");
  const lastName = _get(props, "policy.policyData.lastName");
  const egn = _get(props, "policy.policyData.egn");
  const titularEgn = _get(props, "policy.policyData.titularegn");
  const lkNum = _get(props, "policy.policyData.lkNum");
  const lkDate = _get(props, "policy.policyData.lkDate");
  const titularFirstName = _get(props, "policy.policyData.titularfirstName");
  const titularMiddleName = _get(props, "policy.policyData.titularmiddleName");
  const titularLastName = _get(props, "policy.policyData.titularlastName");
  const ownerData = _get(props, "policy.policyData.savedCar.ownerData");

  const insuredPersons = [];
  if (persons) {
    persons
      .filter(p => p.isInsured === undefined || p.isInsured)
      .forEach(({ firstName, middleName, lastName, egn, foreignerBirthDate, nationality, age, gender, isForeigner, isChield, isAdult }) => {
        insuredPersons.push(
          isForeigner
            ? { firstName, middleName, lastName, egn, age, gender, nationality, foreignerBirthDate, isForeigner, isChield, isAdult }
            : { firstName, middleName, lastName, egn, isChield, isAdult }
        );
      });
  }

  let insurerPerson = _get(persons, "[0].isTitular")
    ? (() => {
        const { firstName, middleName, lastName, egn, foreignerBirthDate, nationality, age, gender, isForeigner } = _get(persons, "[0]", {});
        return isForeigner ? { firstName, middleName, lastName, egn, age, gender, nationality, foreignerBirthDate, isForeigner } : { firstName, middleName, lastName, egn };
      })()
    : null;

  let oneLineOnly;
  let owner;
  if (["GO", "CSC"].includes(productId)) {
    oneLineOnly = insuredPersons.length === 1 && _get(ownerData, "egn") === _get(insurerPerson, "egn");
    if (ownerData) {
      const { egn, firstName, middleName, lastName, eik, companyName, country, dDateOfBirth } = ownerData;

      const ownerFromInsuredPersons = _get(insuredPersons, "[0]", {});
      const isForeigner = ownerFromInsuredPersons?.isForeigner || (country && country !== "BG");
      owner = {
        eik,
        companyName,
        egn,
        firstName,
        middleName,
        lastName,
        ...(isForeigner && {
          isForeigner,
          foreignerBirthDate: ownerFromInsuredPersons?.foreignerBirthDate || "-",
          nationality: ownerFromInsuredPersons?.nationality || "-",
          gender: ownerFromInsuredPersons?.gender || "-"
        })
      };
    } else {
      owner = _get(insuredPersons, "[0]", {});
    }
    if (oneLineOnly && owner) {
      insurerPerson = { ...insurerPerson, ...owner };
    }
  } else {
    oneLineOnly = insuredPersons.length === 1 && _get(insuredPersons, "[0].egn") === _get(insurerPerson, "egn");
  }

  const compareFn = (a, b) => {
    if (getYearsByEgn(a.egn) > getYearsByEgn(b.egn)) {
      return -1;
    } else if (getYearsByEgn(a.egn) < getYearsByEgn(b.egn)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };
  return (
    <>
      {["KID", "MED"].includes(productId) && (
        <div>
          <b>Титуляр:</b>
          <p style={{ fontSize: "11px" }}>
            {titularFirstName
              ? `${titularFirstName} ${titularMiddleName} ${titularLastName} ${titularEgn} лк. № ${lkNum} дата на издаване ${lkDate}`
              : `${firstName} ${middleName} ${lastName} ${egn} лк. № ${lkNum} дата на издаване ${lkDate}`}
          </p>
        </div>
      )}
      {insurerPerson && !["KID", "MED", "OFF"].includes(productId) && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>{oneLineOnly ? `${["GO", "CSC"].includes(productId) ? "Титуляр и Собственик:" : "Титуляр и Застрахован:"}` : "Титуляр:"} </b>
            <span>
              {insurerPerson.firstName} {insurerPerson.middleName} {insurerPerson.lastName}
            </span>
          </div>
          <div className={insurerPerson.isForeigner ? "red" : ""}>
            <b>{insurerPerson.isForeigner ? "ЛНЧ" : "ЕГН"}: </b>
            <span>
              {insurerPerson.egn} {insurerPerson.isForeigner ? "- чужденец" : ""}
            </span>
          </div>
          <div>
            <b>Тел: </b>
            <span>{policy?.phone}</span>
          </div>
          <div>
            <b>E-mail: </b>
            <span>{policy?.email}</span>
          </div>
          {insurerPerson.isForeigner && ["GO", "CSC", "TRA", "MTN", "EXT", "PRP", "HCEF"].includes(productId) ? (
            <>
              <div>
                <b>Рожд. дата: </b>
                <span>{insurerPerson?.foreignerBirthDate || "-"}</span>
              </div>
              <div>
                <b>Пол: </b>
                <span>{insurerPerson?.gender === "1" ? "мъжки" : "женски"}</span>
              </div>
              {productId !== "HCEF" && (
                <div>
                  <b>Националност: </b>
                  <span>{insurerPerson?.nationality || "-"}</span>
                </div>
              )}
            </>
          ) : null}
        </div>
      )}

      {policy?.policyData?.eik && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>Застрахован: </b>
            <span>{policy?.policyData?.companyName}</span>
          </div>
          <div>
            <b>ЕИК: </b>
            <span>{policy?.policyData?.eik}</span>
          </div>
        </div>
      )}
      {["HCEF"].includes(productId) && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>Брой служители: </b>
            <span>{policy?.policyData?.companyWorkers?.length || " - "}</span>
          </div>
          <div>
            <b>Брой членове на семейство: </b>
            <span>{policy?.policyData?.companyWorkers?.reduce((count, worker) => count + (worker.relatives?.length ?? 0), 0) ?? 0}</span>
          </div>
        </div>
      )}
      {["GO", "CSC"].includes(productId) && !oneLineOnly && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>Собственик: </b>
            {owner?.egn && (
              <span>
                {owner?.firstName} {owner?.middleName} {owner?.lastName}
              </span>
            )}
            {owner?.eik && <span>{owner?.companyName}</span>}
          </div>
          {owner?.isForeigner ? (
            <div>
              {owner?.egn && (
                <span className="red block">
                  <b>ЛНЧ:</b>
                  <span>{owner?.egn} - чужденец</span>
                </span>
              )}
              {owner?.foreignerBirthDate && (
                <span className="block">
                  <b>Рожд. дата: </b>
                  <span>{owner?.foreignerBirthDate}</span>
                </span>
              )}
              {owner?.gender && (
                <span className="block">
                  <b>Пол: </b>
                  <span>{owner.gender === "1" ? "мъжки" : "женски"}</span>
                </span>
              )}
              {owner?.nationality && (
                <span className="block">
                  <b>Националност: </b>
                  <span>{owner?.nationality}</span>
                </span>
              )}
            </div>
          ) : (
            <div>
              {owner?.egn && (
                <>
                  <b>ЕГН:</b>
                  <span>{owner?.egn}</span>
                </>
              )}
              {owner?.eik && (
                <>
                  <b>ЕИК:</b>
                  <span>{owner?.eik}</span>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {!!insuredPersons.length && !["GO", "CSC"].includes(productId) && !oneLineOnly && (
        <>
          <b>Застраховани: </b>
          {insuredPersons.sort(compareFn).map((person, index) => {
            return person?.isForeigner ? (
              <p key={index}>
                {person.firstName} {person.middleName} {person.lastName} <span className="red block">{`ЛНЧ: ${person.egn} - чужденец`}</span>
                {index + 1 <= adults ? <b className="block">- възрастен</b> : ""}
                {index + 1 - adults > 0 && index + 1 - adults <= children ? <b>- дете</b> : ""}
                <span className="block">
                  <b>- рожд. дата: </b>
                  {person?.foreignerBirthDate || "-"}
                </span>
                <span className="block">
                  <b>- пол: </b>
                  {person?.gender === "1" ? "мъжки" : "женски"}
                </span>
                <span className="block">
                  <b>- националност: </b>
                  {person?.nationality || "-"}
                </span>
              </p>
            ) : (
              <p key={index}>
                {person.firstName} {person.middleName} {person.lastName} {`ЕГН: ${person.egn}`}
                {person?.isAdult || (index + 1 <= adults && !person?.isChield) ? <b>- възрастен</b> : ""}
                {person?.isChield || (index + 1 - adults > 0 && index + 1 - adults <= children && !person?.isAdult) ? <b>- дете</b> : ""}
              </p>
            );
          })}
        </>
      )}
      {productId === "OFF" && (
        <div style={{ marginBottom: 20 }}>
          {_get(props, "policy.policyData.personType") === 1 && (
            <>
              <div>
                <b>Застраховащ: </b>
                <span>{policy?.name}</span>
              </div>
              <div style={{ marginBottom: 20 }}>
                <b>Лице за контакт: </b>
                <span>{_get(props, "policy.policyData.titularfirstName")}</span>
              </div>
              <div>
                <b>ЕИК: </b>
                <span>{_get(props, "policy.policyData.eik")}</span>
              </div>
            </>
          )}
          {_get(props, "policy.policyData.personType") === 2 && (
            <>
              <div>
                <b>Застраховащ: </b>
                <span>{_get(props, "policy.policyData.titularfirstName")}</span>
              </div>
              <div>
                <b>ЕГН: </b>
                <span>{_get(props, "policy.policyData.egn")}</span>
              </div>
            </>
          )}
          <div>
            <b>Тел: </b>
            <span>{policy?.phone}</span>
          </div>
          <div>
            <b>E-mail: </b>
            <span>{policy?.email}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default TitularAndInsuredPersons;
