import React, { useState } from "react";
import LeftColumnInfo from "./LeftColumnInfo";
import { Box, Button, Divider, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import SelectWithTextInput from "./SelectWithTextInput";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

const ContentWrapperWithButtons = props => {
  const { policy, handleChange, insuranceSum, email, cscService, cscServices, handleSubmit, dialogOpen, setDialogOpen, discount, discounts, disabledBtn } = props;

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Изпращане.."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Оферта ще бъде изпратена на имеил: <b>{email}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Не</Button>
          <Button name="send" onClick={handleSubmit}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <LeftColumnInfo policy={policy} Item={Item} />
        <Grid item xs={10}>
          <Item style={{ textAlign: "left" }}>
            {" "}
            <Typography fontSize={20} mb={2} textAlign="center">
              Плащания:
            </Typography>
            <Grid alignItems="center" container direction="row" justifyContent="space-between">
              <Box mb={2}>
                <TextField
                  type="email"
                  style={{ minWidth: 350 }}
                  onChange={handleChange}
                  name="toEmail"
                  variant="outlined"
                  size="small"
                  label="Изпращане на имейл до:"
                  value={email}
                />
              </Box>
            </Grid>
            <Divider mb={2}></Divider>
            <Grid mt={2} alignItems="center" container direction="row" justifyContent="flex-start">
              <Box mb={1}>
                <TextField
                  type="number"
                  style={{ minWidth: 350 }}
                  onChange={handleChange}
                  name="insuranceSum"
                  variant="outlined"
                  size="small"
                  label="Застрахователна сума"
                  value={insuranceSum}
                />
              </Box>
              <Box ml={3} mb={2}>
                <FormControl style={{ minWidth: 200, marginRight: 20 }} variant="standard">
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Вид сервиз:
                  </InputLabel>
                  <Select
                    SelectDisplayProps={{
                      style: { fontSize: 16 }
                    }}
                    labelId="demo-simple-select-placeholder-label-label"
                    id="outlined-size-small"
                    value={cscService}
                    onChange={handleChange}
                    name="cscSevice"
                  >
                    {cscServices.map(value => {
                      return (
                        <MenuItem key={value.short} value={value.short}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!!discounts?.length && (
                  <TextField
                    sx={{ mb: 0, mt: 1, pl: 0 }}
                    variant="outlined"
                    name={`customDiscount`}
                    label="Отстъпка (процент):"
                    readOnly
                    inputProps={{
                      sx: { pl: 1 }
                    }}
                    value={discount}
                    size="small"
                  />
                )}
              </Box>
            </Grid>
            {props.children}
            <Grid alignItems="center" container direction="row" justifyContent="space-between">
              <Grid item></Grid>
              <Grid>
                <Button name="save" onClick={handleSubmit} sx={{ m: 1 }} type="submit" variant="contained" size="small" endIcon={<Icon>save</Icon>} disabled={disabledBtn}>
                  Запази
                </Button>
                <Button
                  name="send"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  sx={{ m: 1 }}
                  type="button"
                  variant="contained"
                  size="small"
                  endIcon={<Icon>send</Icon>}
                  disabled={disabledBtn}
                >
                  Изпрати
                </Button>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentWrapperWithButtons;
