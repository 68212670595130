import React from "react";
import { Tabs, Typography } from "@mui/material";
import { grayLight } from "../../utils/colors";
import Tab from "@mui/material/Tab";
import "./inner.tabs.sass";
import { useSelector } from "react-redux";

const InnerTabs = ({ selected, handleChange, options, product, variant }) => {
  const count = useSelector(state => state.count);

  return (
    <Tabs value={selected} indicatorColor="" variant="standard" textColor="primary" onChange={handleChange} aria-label="disabled tabs example" sx={{ backgroundColor: grayLight }}>
      {options.map((option, index) => (
        <Tab
          key={`tab-${index}`}
          label={
            <Typography sx={{ m: 0, p: 0 }} className="vertical-align">
              {option.label}
              {!!count?.[product]?.[`${variant}.${option?.value}`] && <span className="chip">{count[product][`${variant}.${option?.value}`]}</span>}
            </Typography>
          }
          value={option?.value}
          className={`inner-tab-item ${selected === option?.value ? "selected" : ""}`}
        />
      ))}
    </Tabs>
  );
};

export default InnerTabs;
