import React from "react";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsBike from "@mui/icons-material/DirectionsBike";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import ChairIcon from "@mui/icons-material/Chair";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CommuteIcon from "@mui/icons-material/Commute";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import BusinessIcon from "@mui/icons-material/Business";

import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import AllInclusive from "@mui/icons-material/AllInclusive";

import PercentIcon from "@mui/icons-material/Percent";
import ConnectWithoutContact from "@mui/icons-material/ConnectWithoutContact";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShieldIcon from "@mui/icons-material/Shield";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import AddIcon from "@mui/icons-material/Add";
import TableRowsIcon from "@mui/icons-material/TableRows";

import * as colors from "../../utils/colors";
import routesConfig from "../../routes/routesConfig";
import { useLocation, useNavigate } from "react-router-dom";
import _get from "lodash.get";
import mainConfig from "../../config/mainConfig";
import NestedItem from "./NestedItem";
import { useSelector } from "react-redux";
import { darken } from "@mui/system";
import { calculateTotalByProduct } from "../../config/countConfig";

const leftMenuConfig = mainConfig?.leftMenu;

const drawerWidth = 210;
const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));
const menuItems = [
  { id: "GO", icon: <ShieldIcon />, label: "Гражданска отговорност", color: "primary", link: routesConfig.go.route },
  { id: "CSC", icon: <DirectionsCarIcon />, label: "Автокаско", color: "purple", link: routesConfig.casco.route },
  { id: "CSC+GO", icon: <AddModeratorIcon />, label: "Каско + Гражданска", color: "pink", link: routesConfig.cascoPlusGo.route },
  { id: "TRA", icon: <AirplanemodeActiveIcon />, label: "Пътуване в чужбина", color: "teal", link: routesConfig.tra.route },
  { id: "PRP", icon: <ChairIcon />, label: "Имущество", color: "warning", link: routesConfig.prp.route },
  { id: "MTN", icon: <FilterHdrIcon />, label: "Планинска застраховка", color: "success", link: routesConfig.mtn.route },
  { id: "EXT", icon: <DirectionsBike />, label: "Екстремни спортове", color: "yellow", link: routesConfig.ext.route },
  { id: "HLT", icon: <MedicalServicesIcon />, label: "Здравe и спокойствие", color: "danger", link: routesConfig.hlt.route },
  { id: "MED", icon: <AllInclusive />, label: "Лечение без граници", color: "danger", link: routesConfig.med.route },
  { id: "LFE", icon: <MonitorHeartIcon />, label: "Здравни застраховки", color: "danger", link: routesConfig.lfe.route }
];
const menuItems2 = [
  { id: "OFF", icon: <BusinessIcon />, label: "Фирмено застраховане", color: "gray", link: routesConfig.off.route },
  { id: "OnlineServices", icon: <CommuteIcon />, label: "Винетки/щети", color: "gray", link: routesConfig.purchases.route },
  { id: "Refunds", icon: <MonetizationOnIcon />, label: "Възстановени суми", color: "gray", link: routesConfig.refunds.route },
  {
    id: "Stickers",
    icon: <FeedOutlinedIcon />,
    label: "БСО",
    color: "gray",
    options: [
      { id: "BsoAdd", icon: <AddIcon />, label: "Добавяне", color: "success", link: routesConfig.addStickers.route },
      { id: "BsoList", icon: <TableRowsIcon />, label: "Грид", color: "yellow", link: routesConfig.stickers.route }
    ]
  },
  { id: "Promo", icon: <PercentIcon />, label: "Промокодове", color: "gray", link: routesConfig.addPromoCodes.route },
  { id: "addReferral", icon: <ConnectWithoutContact />, label: "Реферали", color: "gray", link: routesConfig.addReferral.route },
  { id: "Reports", icon: <AssessmentOutlinedIcon />, label: "Отчети", color: "gray", link: routesConfig.reports.route },
  { id: "kep", icon: <FeedOutlinedIcon />, label: "Подписване с КЕП", color: "gray", link: routesConfig.signingPDF.route }
  //{ id: "Statistics", icon: <QueryStatsIcon />, label: "Statistics", color: "gray", link: routesConfig.statistics.route }
];

export const Item = ({ id, label, icon, color, open, link }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const count = useSelector(state => state.count);
  const currentRoute = _get(location, "pathname", "");
  const calculatedCount = calculateTotalByProduct(count, id) || 0;

  return (
    <ListItem
      key={id}
      disablePadding
      sx={{ display: "block" }}
      selected={currentRoute === link}
      onClick={() => {
        if (link) {
          sessionStorage.removeItem("policiesSelectedTab");
          sessionStorage.removeItem("selectedTab");
          sessionStorage.removeItem("installmentsSelectedTab");
          sessionStorage.removeItem("offerSelectedTab");
          navigate(link);
        }
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 60,
          justifyContent: open ? "initial" : "center",
          py: 0,
          px: 2.5
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            background: colors[`${color}Light`],
            color: colors[color],
            p: 0.5,
            borderRadius: "5px"
          }}
        >
          {calculatedCount > 0 ? (
            <Badge
              badgeContent={calculatedCount}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: darken(colors?.warning || "#FFA500", 0.1),
                  color: "white",
                  fontWeight: "bold",
                  height: "16px"
                }
              }}
              max={99}
            >
              {icon}
            </Badge>
          ) : (
            <>{icon}</>
          )}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0, whiteSpace: open ? "pre-wrap" : "nowrap" }} primaryTypographyProps={{ variant: "body2" }} />
      </ListItemButton>
    </ListItem>
  );
};
const isActiveMenuItem = id => {
  if (leftMenuConfig) {
    return leftMenuConfig?.find(x => x.id === id)?.active;
  }
  return true;
};
const LeftMenu = props => {
  const { DrawerHeader, handleDrawerClose, open } = props;
  const theme = useTheme();

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ py: 0 }}>
        <Item id="Home" label="Всички полици" icon={<HomeIcon />} color="gray" open={open} link={routesConfig.policies.route} />
      </List>
      <Divider />
      <List sx={{ py: 0 }}>
        {menuItems.map(mi => (isActiveMenuItem(mi?.id) ? <Item key={mi?.id} id={mi?.id} label={mi?.label} icon={mi?.icon} color={mi?.color} open={open} link={mi?.link} /> : null))}
        <Divider />
        {menuItems2.map(mi =>
          isActiveMenuItem(mi?.id) ? (
            mi?.options ? (
              <NestedItem key={mi?.id} id={mi?.id} label={mi?.label} icon={mi?.icon} color={mi?.color} open={open} options={mi.options} />
            ) : (
              <Item key={mi?.id} id={mi?.id} label={mi?.label} icon={mi?.icon} color={mi?.color} open={open} link={mi?.link} />
            )
          ) : null
        )}
        <Divider />
      </List>
    </Drawer>
  );
};

export default LeftMenu;
